import React from "react"
import { Link } from "gatsby"
import GatsbyBackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import "../main.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

/** @jsx jsx */
import { css, jsx } from "@emotion/core"

// images
import bfbLogo from "../img/bfb-logo-white.svg"
import bfbHero from "../img/bfb-hero.jpg"
import bfbImage1 from "../img/BFB_FoundryIntro_OOH.jpg"
import bfbImage2 from "../img/BFB_LITTLE_FAIRY_OOH.jpg"
import bfbImage3 from "../img/BFB_TRAIN_WOMAN_OOH.jpg"
import bfbVideo from "../vid/bfb-video.mp4"
import bfbVideoPoster from "../img/bfb-video-poster.jpg"

const BlueFoundryBankPage = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Layout>
      <SEO title="Blue Foundry Bank" />
      <div className="all">
        <div id="cases">
          <div id="bfb" className="case">
            <div className="close">
              <Link to="/">Go Back Home</Link>
            </div>
            <div className="mast shift bfb-bkg">
              <img src={bfbLogo} alt="Blue Foundry Bank Logo" />
              <h1>
                <span>Blue Foundry Bank</span>
              </h1>
              <h2>Catch helps an 80 year old bank get millennial ready</h2>
            </div>
            <section className="intro">
              <p>
                Blue Foundry Bank, formerly known as Boiling Springs Savings
                Bank operated in Northern New Jersey for over 80 years. To say
                it needed to be reinvented to survive in the competitive retail
                bank market is an understatement. It was the model of a
                traditional community savings bank and their customers were
                aging (65% were over 65).
              </p>
            </section>
            <section className="work">
              <ul>
                <li>
                  <video
                    className="bfb-video"
                    controls
                    playsInline
                    alt="Blue Foundry Bank video"
                    src={bfbVideo}
                    poster={bfbVideoPoster}
                  />
                </li>
              </ul>
            </section>
            <section className="challenge">
              <div className="section-container">
                <h2 className="section-h2">Challenge</h2>
                <p>
                  The issues were threefold:
                  <ul>
                    <li className="challege-bullets">
                      Financial – get more assets under management
                    </li>
                    <li className="challege-bullets">
                      Perceptual – differentiate within a crowded marketplace –
                      move from a "dated savings bank" to a "progressive/modern
                      banking solutions provider"
                    </li>
                    <li className="challege-bullets">
                      Customer – attract a younger consumer with a greater LTV
                    </li>
                  </ul>
                </p>
              </div>
            </section>
            <section className="work">
              <div className="small">
                <h2 className="section-h2">Target</h2>
                <p>
                  Through syndicated research we identified a core group we
                  called "Established Millennials" (EMs). EMs were on the older
                  edge of the Millennial spectrum (28 to 38). They were opening
                  more profitable accounts, making bigger life purchases and
                  decisions but at the same time were less price sensitive and
                  less brand loyal so they were ripe for the picking.
                </p>
              </div>
            </section>
            <section className="work">
              <div className="small">
                <h2 className="section-h2">Strategy</h2>
                <p>
                  Our consumer research helped to tease out the good and bad of
                  community banking. By focusing on the high touch and
                  personalized approach that local banks can provide while
                  reassuring the delivery/access to "big bank" products and
                  services, Blue Foundry Bank had a story to tell. But we also
                  needed to connect with the "doer" mentality of our target.
                  They were in a critical life stage where they were literally
                  building their futures. Tapping into this emotion helped to
                  shape our key message.
                </p>
              </div>
            </section>
            <section className="work">
              <div className="small">
                <h2 className="section-h2">Key Message</h2>
                <p>
                  Blue Foundry Bank is the bank for movers, shakers and makers.
                </p>
              </div>
            </section>
            <section className="work">
              <ul>
                <li>
                  <img
                    className="bfb-images"
                    src={bfbImage1}
                    alt="Blue Foundry Bank out of home of a masonary worker."
                  />
                </li>
              </ul>
            </section>
            <section className="work">
              <div className="small">
                <h2 className="section-h2">Creative</h2>
                <p>
                  The creative expression of our strategy needed to be bold in
                  order to differentiate the brand and to connect with our
                  target. Being perceived as a bank that acts like no other, was
                  our measuring stick across all channels. Bold, unexpected
                  visuals depicting both "Foundry" imagery as well as the
                  "Doers" that comprised our core business and consumer
                  customers were framed by elements of our new brand identity.
                  This approach was applied to both brand work and tactical
                  efforts.
                </p>
              </div>
            </section>
            <section className="work">
              <ul>
                <li>
                  <img
                    className="bfb-images"
                    src={bfbImage2}
                    alt="Blue Foundry Bank out of home ad of a girl dressed as a fairy."
                  />
                </li>
                <li>
                  <img
                    className="bfb-images"
                    src={bfbImage3}
                    alt="Blue Foundry Bank out of home of a lady on a train."
                  />
                </li>
              </ul>
            </section>
            <section className="work">
              <div className="small">
                <h2 className="section-h2">Results</h2>
                <p>
                  At Catch we take data analytics very seriously. We understand
                  the importance of being able to measure our efforts and track
                  back to ROI. Nowhere is that more important than when dealing
                  with financial institutions. Communications spending had to
                  deliver upon specific basis point goals across both personal
                  and business initiatives that correlated to new account
                  openings
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlueFoundryBankPage
